@import '~bootstrap/scss/bootstrap.scss';
@import '~react-bootstrap-typeahead/css/Typeahead.css';
@import '~react-phone-input-2/lib/bootstrap.css';
@import '~rc-slider/assets/index.css';
@import '_modal.scss';
@import '_reactdatepicker.scss';
@import 'model.scss';
@import 'country.scss';

@tailwind components;
@tailwind utilities;

$nav-topbar-height: 56px;
.nav-link {
  cursor: pointer;
}
.nav-sidebar {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  z-index: 100; /* Behind the navbar */
  padding: $nav-topbar-height 0 0; /* Height of navbar */
  transition: width 0.3s ease-in-out;

  &-panel {
    height: calc(100vh - #{$nav-topbar-height});
  }

  &-section {
    font-size: $font-size-sm;
  }

  .nav-item {
    .active {
      background-color: $gray-200;
    }
  }
}

.nav-sidebar-closed {
  width: 30px;
}

.nav-sidebar-panel-closed {
  visibility: hidden;
}

.nav-toggler {
  cursor: pointer;
}

.nav-icon {
  height: $font-size-base;
  width: $font-size-base;
}

.tableFixHead {
  overflow: auto;
  height: 75vh;
}

.tableFixHead thead th {
  position: sticky;
  top: 0;
  z-index: 1;
}

.tableFixHead tbody th {
  position: sticky;
  left: 0;
}

table {
  border-collapse: collapse;
  width: 100%;
}

th {
  background: white;
}

.icon-button {
  cursor: 'pointer';
}

.icon-button:hover {
  background-color: darkturquoise;
}

.icon-button:active {
  width: 30px;
  height: 30px;
}
